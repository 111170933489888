<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="handleAi">AI生图</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="handleShare">抖音分享</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        @selection-change="handleSelectionChange"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键">
            </el-table-column>
            <el-table-column
                prop="name"
                label="文件名称">
            </el-table-column>
            <el-table-column
                prop="path"
                label="文件地址">
                <template slot-scope='scope'>
                  <el-tooltip class="item" effect="dark" :content="scope.row.path" placement="top">
                    <el-button @click="() => handleCopy(scope.row.path)">复制</el-button>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="source"
                label="来源">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态">
            </el-table-column>
            <el-table-column
                prop="createUserId"
                label="创建人id">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="path"
                label="图片"
                width="200">
                <template slot-scope='scope'>
                  <img :src="scope.row.path" width="100px" v-if="scope.row.path.indexOf('.png') >= 0">
                  <audio controls width="100px" v-else-if="scope.row.path.indexOf('.wav') >= 0">
                      <source :src="scope.row.path" type="audio/wav">
                      您的浏览器不支持播放该音频格式。
                  </audio>
                  <video width="100px" v-else :src="scope.row.path"></video>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='500px' :before-close='cancel'>
        <el-upload
          class="upload-demo"
          :action="upload.url"
          :headers="upload.headers"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="handleSuccess"
          :multiple="false"
          :file-list="upload.fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
        <div slot='footer' class='dialog-footer'>
        <el-button @click='cancel'>关闭</el-button>
        </div>
    </el-dialog>
    
    <!-- 添加或修改对话框 -->
    <el-dialog title='AI生图' :visible.sync='openAi' width='500px' :before-close='cancel'>
        <el-input v-model='ai_content' placeholder='请输入内容' />
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='aiUpload'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>

    <!-- 抖音分享 -->
    <el-dialog title="抖音分享" :visible.sync='shareInfo.open' width='80%' :before-close='cancel'>
      <el-card>
        <div slot="header" class="clearfix">
          <span>资源文件</span>
        </div>
        <el-row>
          <el-col :span="8" v-for="(item, index) in shareInfo.list" :key="index">
            <img :src="item" width="100px" v-if="item.indexOf('.png') >= 0">
            <audio controls width="100px" v-else-if="item.indexOf('.wav') >= 0">
                <source :src="item" type="audio/wav">
                您的浏览器不支持播放该音频格式。
            </audio>
            <video width="100px" v-else :src="item"></video>
          </el-col>
        </el-row>
      </el-card>
      <el-card>
        <div slot="header" class="clearfix">
          <span>内容</span>
        </div>
        <el-select
          v-model="shareInfo.hashtagList"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="请选择话题">
          <el-option
            v-for="(item, index) in shareInfo.hashtagList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
        <el-card>
          <div slot="header" class="clearfix">
            <span>小程序</span>
          </div>
          <el-input v-model='shareInfo.microAppInfo.appId' placeholder='请输入小程序ID' />
          <el-input v-model='shareInfo.microAppInfo.appTitle' placeholder='请输入小程序标题' />
          <el-input v-model='shareInfo.microAppInfo.description' placeholder='请输入小程序描述语' />
          <el-input v-model='shareInfo.microAppInfo.appUrl' placeholder='请输入小程序地址' />
        </el-card>
        <el-switch
          v-model="shareInfo.shareToPublish"
          active-text="发布页"
          :active-value="1"
          inactive-text="编辑页"
          :inactive-value="0"
          >
        </el-switch>
        <el-input v-model='shareInfo.title' placeholder='请输入视频标题' />
        <el-input v-model='shareInfo.poiId' placeholder='请输入地理位置锚点' />
      </el-card>
      <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { queryList, aiImageUpload } from '@/service/fileInfo';
import { addShare } from '@/service/dyShare';
import store from '@/store';

const IMAGE_TYPE = ['png', 'jpg'];
const VIDEO_TYPE = ['mp4'];
const VALID_TYPE = IMAGE_TYPE.concat(VIDEO_TYPE);

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        code: [
          { required: true, message: '角色代码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
      },
      upload: {
        url: process.env.VUE_APP_APIHOST + '/file_info/upload',
        headers: {
          Authentication: store.getters.token
        },
        fileList: []
      },
      openAi: false,
      ai_content: '',
      multipleSelection: [],
      shareInfo: {
        open: false,
        list: [],
        imagePath: "",
        imageListPath: [],
        videoPath: "",
        hashtagList: [],
        microAppInfo: {
          appId: "",
          appTitle: "",
          description: "",
          appUrl: ""
        },
        shareToPublish: null,
        title: "",
        poiId: ""
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() { 
      queryList(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {};
    },
    tableEdit(row){
        this.title = '修改';
        this.open = true;
        this.form = {...row};
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.openAi = false;
      this.shareInfo = {
        open: false,
        list: [],
        imagePath: "",
        imageListPath: [],
        videoPath: "",
        hashtagList: [],
        microAppInfo: {
          appId: "",
          appTitle: "",
          description: "",
          appUrl: ""
        },
        shareToPublish: null,
        title: "",
        poiId: ""
      };
      this.resetForm('form');
      this.getList();
    },
    /** 提交按钮 */
    submitForm: function() {
      addShare(this.shareInfo).then(response => {
        if (response.code === 0) {
          this.cancel();
          this.getList();
        } else {
          this.$message({
              type: 'error',
              message: '异常'
          });  
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleSuccess(){
      this.cancel();
      this.getList();
    },
    handleAi(){
      this.openAi = true;
      this.ai_content = '';

    },
    aiUpload(){
      aiImageUpload({content: this.ai_content}).then(response => {
        if (response.code === 0) {
          this.cancel();
          this.getList();
        } else {
          this.$message({
              type: 'error',
              message: '异常'
          });  
        }
      });
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    handleShare(){
      if(this.multipleSelection === null || this.multipleSelection.length === 0){
        this.$message({
            type: 'error',
            message: '请选择素材'
        });  
        return;
      }
      let selectionOne = this.multipleSelection[0];
      let oneSplit = selectionOne.path.split('.');
      let suffix = oneSplit[oneSplit.length - 1];
      const isImage = IMAGE_TYPE.indexOf(suffix) >= 0;
      const pathList = this.multipleSelection.map((item) => item.path);
      const validPathList = pathList.filter(p => {
        let pSplit = p.split('.');
        return VALID_TYPE.indexOf(pSplit[pSplit.length - 1]) < 0;
      });
      if(validPathList !== null && validPathList.length > 0){
        this.$message({
          type: 'error',
          message: '存在无效素材'
        });  
        return;
      }
      if(isImage) {
        const newPathList = pathList.filter(p => {
          let pSplit = p.split('.');
          return IMAGE_TYPE.indexOf(pSplit[pSplit.length - 1]) < 0;
        });
        if(newPathList !== null && newPathList.length > 0){
          this.$message({
            type: 'error',
            message: '素材冲突'
          });  
          return;
        }
      } else {
        const newPathList = pathList.filter(p => {
          let pSplit = p.split('.');
          return IMAGE_TYPE.indexOf(pSplit[pSplit.length - 1]) >= 0;
        });
        if(newPathList !== null && newPathList.length > 0){
          this.$message({
            type: 'error',
            message: '素材冲突'
          });  
          return;
        }
      }
      const isList = pathList.length > 1;
      this.shareInfo = {
        ...this.shareInfo,
        open: true,
        list: pathList,
        imagePath: !isList ? (isImage ? pathList[0] : "") : "",
        imageListPath: isList ? (isImage ? pathList : []) : [],
        videoPath: !isList ? (!isImage ? pathList[0] : "") : ""
      };
    }
  }
};
</script>