import request from '@/service/lib/request';
// 抖音分享/发布记录

/**
 * 分页
 * @author lb
 */
export function getSharePage(data) {
    return request({
      url: '/dy_share_history/get_share_history_page',
      method: 'post',
      data
    });
}

/**
 * 新增分享记录
 * @author lb
 */
export function addShare(data) {
  return request({
    url: '/dy_share_history/add_share_history',
    method: 'post',
    data
  });
}

/**
 * 新增分享记录
 * @author lb
 */
export function updateShare(data) {
  return request({
    url: '/dy_share_history/update_share_history',
    method: 'post',
    data
  });
}

/**
 * 获取分享Schema
 * @author lb
 */
export function getShareUrl(data) {
  return request({
    url: '/dy_share_history/get_share_schema',
    method: 'post',
    responseType: 'blob',
    data
  });
}
